import React from "react"

import SEO from "../../components/seo"
import Layout from "../../layouts/main"
import Configurator from "../../components/Configurator"
import Grid from '@material-ui/core/Grid'
import { makeStyles } from '@material-ui/core';


const useStyles = makeStyles((theme) => ({
  gridWrapper: {
    minHeight: "100vh",
    overflowX: "hidden",
    padding: theme.spacing(1)
  },
  gridItem: {
    overflow: "hidden",
    paddingBottom: theme.spacing(4),
  }
}))

const ConfiguratorPage = ({location}) => {
  const classes = useStyles()

  return (
  <Layout location={location} secondaryBackgroundColor>
    <SEO title="Configurator" />
    <Grid 
    container  
    justify="center"
    alignItems="flex-start"
    className={classes.gridWrapper}
    >
        <Grid className={classes.gridItem} item md={8} xs={12}>
            <Configurator/>
        </Grid>    
    </Grid>    
  </Layout>
)}

export default ConfiguratorPage